<template>
	<Metier name="medecine-du-travail" title="Médecine du travail" :main-menu="[{title: 'Médecin du travail', missions: ['Assure les visites médicales du personnel', 'Conseille les salariés, l\'employeur et les instances représentatives des salariés sur la prévention des risques professionnels et la santé au travail', 'Participe à l\'élaboration des plans de préventions, des fiches individuelles de prévention des expositions et aux actions de prévention (sensibilisation, formation…)', 'Réalise des actions en milieu de travail afin de proposer des améliorations des conditions de travail', 'Supervise les soins d\'urgence et participe aux enquêtes relatives aux accidents de travail'], competences: ['Formation en médecine', 'Diplôme d\'études spécialisées en médecine du travail', 'Connaissance du matériel médical', 'Connaissances du milieu et des risques relatifs au travail industriel'], qualites: ['Sang-Froid', 'Rigueur', 'Discrétion', 'Sens de l\'écoute']}, {title: 'Infirmier(e)', missions: ['Assiste le médecin du travail dans le suivi médical du personnel de la Plateforme', 'Assure le secrétariat médical', 'Participe à la sensibilisation du personnel à la prévention médicale et à l\'hygiène industrielle'], competences: ['Formation d\'infirmier', 'Connaissance du matériel médical', 'Connaissance du milieu et des risques industriels'], qualites: ['Sang-froid', 'Sens de l\'écoute', 'Discrétion']}]">
	</Metier>
</template>

<script>
	export default {
		components: {
			Metier: () => import('@/components/Metier.vue'),
		},
	}
</script>
